<template>
  <div class="v_login">
    <div class="v-login-title">
      <span>登录</span>
      <!-- <div class="v-login-line"></div> -->
    </div>
    <div class="v-login-form g-flex-column g-flex-align-center">
      <div class="v-login-form-item g-flex-column">
        <div class="v-login-form-item-title">
           <span>手机号</span>
           <!-- <div class="v-login-line"></div> -->
        </div>
        <van-field type="tel" v-model="form.mobile"  placeholder="请输入手机号" clearable/>
      </div>
      <!-- 验证码 -->
      <!-- <div class="v-login-form-item g-flex-column">
       <div class="v-login-form-item-title">
           <span>验证码</span>
        </div>
        <div class="v-login-form-item-code g-flex-align-center">
          <van-field v-model="form.mobile_code"  placeholder="请输入获取的验证码"/>
          <div class="v-login-code-btn g-flex-align-center g-flex-justify-center" @click="getCode">{{sendCodeText}}</div>
        </div>
      </div> -->
      <div class="v-login-form-item g-flex-column">
       <div class="v-login-form-item-title">
           <span>登录密码</span>
           <!-- <div class="v-login-line"></div> -->
        </div>
        <div class="v-login-form-item-pwd g-flex-align-center">
          <van-field v-model="form.password" :type="pwdType"  placeholder="请输入登录密码"/>
          <i class="iconfont" :class="pwdType ==='password' ? 'icon-bukejian': 'icon-mimakejianguanbi'" @click="pwdTypeClick"></i>
        </div>

      </div>
    </div>

    <div class="g-flex-justify-between">
      <operation-button :buttonObj="buttonObj" @clickItem="emitLoginClick"/>
    </div>

    <div class="v-login-bottom g-flex-justify-between">
      <span class="v-login-forget" @click="$router.push({ name: 'lookfor' })">忘记密码?</span>
      <span class="v-login-reg" @click="$router.push({ name: 'register' })">新用户注册</span>
    </div>
  </div>
</template>

<script>
import OperationButton from '../../components/OperationButton.vue'
import { apiLogin, apiSendCode } from '../../utils/api.js'
  export default {
    components: { OperationButton },
    data() {
      return {
        pwdType: 'password',
        timer: 0,
        sendCodeText: '获取',
        defaultCodeText:'重新获取',
        form: {
          mobile:'',
          password: '',
          mobile_code: '',
        },
        buttonObj: {
          title: '登录',
          claSS: 'c-btn-default',
          event: 'apiLoginHandel'
        }
      }
    },
    methods: {
      // 获取验证码
      getCode() {
        if(!(/^1\d{10}$/.test(this.form.mobile))) return this.$toast('手机号码有误，请重试'); 
        // if(!(/^1(3[0-9]|5[0-3,5-9]|7[1-3,5-8]|8[0-9])\d{8}$/.test(this.form.mobile))) return this.$toast('手机号码有误，请重试'); 
        if(this.timer) return
        this.apiSendCodeHandel()
      },

      // 发送验证码
      async apiSendCodeHandel() {
        this.$global.loadingShow = true
        const { success, data } = await apiSendCode({ mobile: this.form.mobile })
        if(!success) return
        let timeNum = 60
        this.$toast(data.msg)
        this.timer = setInterval(() => {
          timeNum --
          this.showNum = timeNum === 0 ? false : true
          this.sendCodeText = timeNum === 0 ? this.defaultCodeText : timeNum + 's'
          if(timeNum === 0) {
            clearInterval(this.timer)
            this.timer = 0
          }
        }, 1000)
      },
      // 密码切换
      pwdTypeClick() {
        if(this.pwdType === 'password') return this.pwdType = 'text'
        return this.pwdType = 'password'
      },

      // 登录
      async apiLoginHandel() {
        // if(!(/^1(3[0-9]|5[0-3,5-9]|7[1-3,5-8]|8[0-9])\d{8}$/.test(this.form.mobile))) return this.$toast('手机号码有误，请重试'); 
        if(!(/^1\d{10}$/.test(this.form.mobile))) return this.$toast('手机号码有误，请重试'); 
        // if(!this.form.mobile_code) return this.$toast('手机验证码不能为空')
        if(!this.form.password) return this.$toast('密码不能为空')
        const { success, data } = await apiLogin(this.form)
        console.log(data)
        if(!success) return
        this.$global.setToken(data.token)
        this.$ws.setToken(data.token)
        this.$toast(data.msg)
        this.$router.replace({ path:'/' })
      },

      // 登录按钮触发
      emitLoginClick(event) {
        if(!event) return
        this[event]()
      },
    }
  }
</script>

<style lang="scss">
.v_login {
  width: 100%;
  height: 100%;
  overflow: auto;
  // 登录标题
  .v-login-title {
    width: 130px;
    height: 100px;
    font-weight: bold;
    color: $black;
    position: relative;
    span {
      font-size: 32px;
      position: absolute;
      bottom: 0px;
      z-index: 99;
      right: 0;
    }
    .v-login-line {
      position: absolute;
      height: 10px;
      width: 100%;
      background: $main_color;
      bottom: 0;
      z-index: 88;
    }
  }
  // 表单
  .v-login-form {
    padding: 50px 40px 30px 50px;
    .v-login-form-item {
      align-self: stretch;
      margin-top: 10px;
      .v-login-form-item-title {
        height: 30px;
        position: relative;
        span {
          padding-left: 10px;
          position: absolute;
          font-size: 18px;
          bottom: 0;
          z-index: 99;
          color: $black;
          font-weight: 600;
        }
        .v-login-line {
          position: absolute;
          height: 3px;
          width: 40px;
          background: $main_color;
          bottom: 4px;
          left: 34px;
          z-index: 88;
        }
      }
      // 密码
      .v-login-form-item-pwd {
        position: relative;
        .iconfont {
          position: absolute;
          right: 0;
          font-size: 24px;
          padding: 5px;
        }
      }
      
      // 验证码
      .v-login-form-item-code {
        border-bottom: 1px solid #B5B5B5;
        .van-cell {
          border-bottom: 0;
          flex: 1;
        }
        .v-login-code-btn {
          width: 70px;
          height: 30px;
          background: #FED9E1;
          color: $main_color;
          border-radius: 20px;
          font-size: 14px;
        }
      }

      // 设置输入框
      .van-cell {
        padding-left: 10px;
        border-bottom: 1px solid #B5B5B5;
        input {
          &::-ms-input-placeholder {
            color: #B2B2B2;
          }
          &::-webkit-input-placeholder {
            color: #B2B2B2;
          }
        }
      }
      &:nth-of-type(3) {
        .v-login-form-item-title {
          .v-login-line {
            width: 60px;
          }
        }
      }
    }
  }

  .v-login-bottom {
    padding: 10px 30px;
    .v-login-forget {
      color: $black;
      font-size: 12px;
      padding: 6px;
    }
    .v-login-reg {
      color: $main_color;
      font-size: 12px;
      padding: 6px;
    }
  }
}
</style>